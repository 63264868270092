import React from "react";
import styled from "@emotion/styled";
import { Navigation, NavigationItem } from "./Navigation";
import mq from "../../util/mediaQueries";
import Logo from "../../images/kjell7.inline.svg";

const Kjell7 = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 18px 0 10px 0;
  flex: 2;

  ${mq[0]} {
    max-width: 320px;
    width: 320px;
  }

  ${mq[1]} {
    max-width: 576px;
    width: 576px;
  }

  ${mq[2]} {
    max-width: 768px;
    width: 768px;
  }

  ${mq[3]} {
    max-width: 960px;
    width: 960px;
  }

  ${mq[4]} {
    max-width: 1200px;
    width: 1200px;
  }
`;

const Footer = styled.div`
  width: 100%;
  background-color: black;
  padding: 10px 8px;
  text-align: center;
  color: gray;
  font-size: 10px;

  img {
    width: 100px;
  }

  hr {
    border-color: gray;
    margin: 20px auto;
    width: 100px;
  }
`;

const Header = styled.div`
  width: 100%;
  background-color: black;
  padding: 10px 30px;
  display: flex;
  position: relative;
`;

export default function Layout({ children }) {
  return (
    <Kjell7>
      <Header>
        <Navigation>
          <NavigationItem to="/news">News</NavigationItem>
          <NavigationItem to="/live">Live</NavigationItem>
        </Navigation>
      </Header>

      <Content>{children}</Content>

      <Footer>
        <Logo />
        <div>
          <hr></hr>
          <p>&copy; 2020 KJELL7 Media Group</p>
        </div>
      </Footer>
    </Kjell7>
  );
}
