import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import mq from "../../util/mediaQueries";
import Logo from "../../images/kjell7.inline.svg";

const NavigationContainer = styled.div`
  display: flex;
  ul {
    list-style: none;
    display: none;
    &.open {
      margin: 0;
      padding: 0;
      display: block;
      background: black;
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      z-index: 1;

      li {
        padding: 1em 2em;
      }
    }
  }
  button {
    border: none;
    background: transparent;
    margin-right: 1em;
    i {
      color: white;
      font-size: 32px;
    }
  }
  ${mq[2]} {
    ul {
      display: flex;
    }
    button {
      display: none;
    }
  }
`;

export const Navigation = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = React.useCallback(() => setOpen(!open), [open]);
  return (
    <NavigationContainer>
      <button onClick={onClick}>
        <i className="fas fa-bars"></i>
      </button>
      <Link to="/">
        <Logo />
      </Link>
      <ul className={open ? "open" : ""}>{children}</ul>
    </NavigationContainer>
  );
};

export const NavigationItem = ({ to, children }) => (
  <li
    css={css`
      color: white;
      padding: 0px 20px;
      a {
        color: white;
        text-decoration: none;
        &.live {
          color: red;
        }
      }
    `}
  >
    <Link to={to}>{children}</Link>
  </li>
);
